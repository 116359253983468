<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-user"></i>{{ $t('User Roles') }}
    </h2>
    <v-row class="mt-10">
      <v-col cols="3">
        <!-- data table -->
        <v-data-table
          :headers="tableHeaders"
          v-model="selectedAcc"
          :items="accessLevelList"
          item-key="accessLevel"
          :page.sync="page"
          :hide-default-footer="true"
          @click:row="onRowClickAccessLevel"
          single-select
          height="650"
          class="tbl-type03"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.title`]="{ item }">
            <v-text-field
              v-if="item.editable"
              dense
              hide-details
              v-model="item.title"
              placeholder="item.title"
              class="form-input"
              readolny
              clearable
            ></v-text-field>
            <span v-else>{{ $t(item.title) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item.accessLevel != 1" @click="onTitleEdit(item)">
              <img src="@/assets/img/ico-edit.png" alt="" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="1" class="d-flex justify-center align-center">
        <img src="@/assets/img/ico-next-step.png" alt="" />
      </v-col>
      <v-col cols="4">
        <h3 class="tit-treeBox">{{ $t('MENU ACCESS') }}</h3>
        <div class="treeBox">
          <template>
            <v-treeview
              v-model="selectedMenu"
              :items="accessMenuTree"
              item-disabled="primary"
              open-all
              selectable
              dense
              shaped
              selected-color="#001e38"
              class="tree_selector"
            >
            <template v-slot:label="{ item }">
              <span>{{ $t(item.name) }}</span>
            </template>
           </v-treeview>
          </template>
        </div>
        <div>
          <v-btn
            @click="onSaveMenu"
            :disabled="userLevelSaveResetDisabled || buttondisabled"
            text
            class="btn t-bottom"
            >{{ $t('Save') }}
          </v-btn>
          <v-btn
            @click="onResetMenu"
            :disabled="userLevelSaveResetDisabled || buttondisabled"
            text
            class="btn t-bottom ml-2"
            >{{ $t('Reset') }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="4">
        <h3 class="tit-treeBox">{{ $t('ACTION ACCESS') }}</h3>
        <div class="treeBox">
          <template>
            <v-treeview
              v-model="selectedAction"
              :items="accessActionTree"
              item-disabled="primary"
              open-all
              selectable
              dense
              shaped
              selected-color="#001e38"
              class="tree_selector"
            >
            <template v-slot:label="{ item }">
              <span>{{ $t(item.name) }}</span>
            </template>
          </v-treeview>
          </template>
        </div>
        <div>
          <v-btn
            @click="onSaveAction"
            :disabled="userLevelSaveResetDisabled || buttondisabled"
            text
            class="btn t-bottom"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            @click="onResetAction"
            :disabled="userLevelSaveResetDisabled || buttondisabled"
            text
            class="btn t-bottom ml-2"
          >
            {{ $t('Reset') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import configs from '@/plugins/configs'

const CUSTOMER = configs.Customer

const requests = {
  getAccessLevels: {
    method: 'get',
    url: '/api/common/accessLevel'
  },
  updateAcessLevelTitle: {
    method: 'put',
    url: '/api/common/accessLevel/title'
  },
  getDefaultAccessMenu: {
    method: 'get',
    url: '/api/common/accessLevel/menu/reset'
  },
  getDefaultAccessAction: {
    method: 'get',
    url: '/api/common/accessLevel/action/reset'
  },
  updateAccessMenu: {
    method: 'put',
    url: '/api/common/accessLevel/menu'
  },
  updateAccessAction: {
    method: 'put',
    url: '/api/common/accessLevel/action'
  }
}

// const essentialItems = ['8400']
// For MY Account Menu "8400"
const essentialItems = []

const primaryMenu = ['8300']
const primaryAccess = ['8300']
// const essentialAccessItems = ['8400', '8401']
// For MY Account Menu access Action "8400" "8401"
const essentialAccessItems = []

function getMenuGroup (menuId) {
  // For Metro
  if (menuId && menuId.length > 4) {
    return menuId.toString().substr(0, 2)
  } else {
    return menuId.toString().substr(0, 1)
  }
}

export default {
  name: 'UserLevel',
  data () {
    return {
      page: 1,
      pageCount: 0,
      selectedAcc: [],
      accessLevelList: [],
      buttondisabled: false,
      selectedLevel: null,
      selectedMenu: [],
      selectedAction: [],
      disabeldLinks: [
        {
          key: 'lbsEnable',
          parentCode: '6000',
          codeList: ['6100', '6600']
        },
        {
          key: 'templateGroupEnable',
          parentCode: '5000',
          codeList: ['5200', '5300']
        }
      ],
      userLevelSaveResetDisabled: false,
      lcdTabEnable: localStorage.getItem('LcdTabEnable')
    }
  },
  computed: {
    user () {
      return this.$store?.state?.auth?.user
    },
    editingMyLevel () {
      return (
        this.selectedLevel?.accessLevel === this.user?.accountInfo?.accessLevel
      )
    },
    tableHeaders () {
      return [
        {
          text: this.$t('Level'),
          sortable: false,
          align: 'center',
          value: 'accessLevel'
        },
        {
          text: this.$t('TITLE'),
          sortable: false,
          align: 'center',
          value: 'title'
        },
        { text: '', sortable: false, align: 'center', value: 'actions' }
      ]
    },
    company () {
      const account = this.$store.state.auth.user
      return account && account.company
    },
    accessMenuTree () {
      var accessMenu = this.$store.getters['dataStore/GET_MENU']
      // LCD
      if (this.lcdTabEnable === 'false') {
        var data = ['20000', '21000', '22000', '22100', '23000', '23100', '23200', '24000', '24100', '25000', '25100']
        accessMenu = accessMenu.filter(d => !data.includes(d.code))
      }
      // LCD
      return this.makeMenuTree(accessMenu, primaryMenu)
    },
    accessActionTree () {
      var accessAction = this.$store.getters['dataStore/GET_ACTION']
      // LCD
      if (this.lcdTabEnable === 'false') {
        var data = ['20000', '21000', '22000', '22100', '22001', '22002', '22003', '22004', '22005', '22006', '22007', '23000', '23100', '23101', '23102', '23200', '23201', '23202', '23203', '24000', '24100', '24001', '24002', '24003', '24004', '25000', '25100', '25001', '25002']
        accessAction = accessAction.filter(d => !data.includes(d.code))
      }
      // END LCD
      return this.makeAccessTree(accessAction, primaryAccess)
    }
  },
  mounted () {
    // 상단 공통 팝업 & 자동완성기능 사용유무
    EventBus.$emit('enableSelectedStores', false)
    this.$store.dispatch('auth/getDisabledBtn', '8300').then(flag => {
      this.userLevelSaveResetDisabled = flag
    })
    this.getAccessLevels()
    if (this.$store.getters['auth/GET_USER_LEVEL'] === '1') {
      this.buttondisabled = false
    } else {
      this.buttondisabled = true
    }
    setTimeout(() => {
      this.lcdTabEnable = localStorage.getItem('LcdTabEnable')
    }, 500)
  },
  methods: {
    makeMenuTree (list, primaryItems) {
      var noAccMenu
      if (!commons.isValidStr(list)) return
      if (CUSTOMER !== 'METRO') {
        noAccMenu = ['1000', '2200', '2300', '4200', '6300', '11000', '11100', '11200', '5500', '21000']
      } else {
        noAccMenu = ['1000', '2200', '2300', '4200', '6300', '21000']
      }

      const disabeldLinks = this.setDisabled(this.disabeldLinks)
      for (const link of disabeldLinks) {
        if (link.disabled === true) noAccMenu.push(...link.codeList)
      }
      const tree = list.reduce((total, currentValue) => {
        const treeItem = {
          id: currentValue.code,
          name: currentValue.name
        }
        // Primary item 이면 마크.
        if (primaryItems.includes(treeItem.id)) treeItem.primary = true
        let parent = null
        for (let index = 0; index < total.length; index++) {
          const element = total[index]
          // // LCD
          // if (
          //   !noAccMenu.includes(treeItem.id) && element.id === '20000'
          // ) {
          //   parent = element
          //   break
          // } else
          // if (
          //   !noAccMenu.includes(treeItem.id) &&
          //   (getMenuGroup(treeItem.id) === getMenuGroup(element.id)) && (treeItem.id !== '20000' && treeItem.id !== '21000' &&
          //   treeItem.id !== '22100' && treeItem.id !== '23000' && treeItem.id !== '23100' &&
          //   treeItem.id !== '23200' && treeItem.id !== '24000' && treeItem.id !== '24100' && treeItem.id !== '25000' && treeItem.id !== '25100'
          //   )
          // ) {
          //   parent = element
          //   break
          // }
          // LCD end

          if (
            !noAccMenu.includes(treeItem.id) &&
            getMenuGroup(treeItem.id) === getMenuGroup(element.id)
          ) {
            // Parent가 있는 경우 현재 요소를 해당 Parent의 child로 포함.
            parent = element
            break
          }
        }
        if (parent !== null) {
          // 부모 요소가 존재하는 경우.
          if (!parent.children) parent.children = []
          if (primaryItems.includes(parent.id)) parent.primary = true
          parent.children.push(treeItem)
        } else {
          // 부모 요소 생성되지 않은 경우.
          if (!commons.has(noAccMenu, treeItem.id)) {
            total.push(treeItem)
          }
        }
        return total
      }, [])
      // 부모 없이 자식만 있는 경우 부모를 생성
      tree.forEach(x => {
        if (!x.children) {
          x.children = [{ id: x.id, name: x.name }]
          x.id = x.id + 1
        }
      })
      return tree
    },
    makeAccessTree (accessAction, primaryItems) {
      var noAccMenu
      if (!commons.isValidStr(accessAction)) return
      accessAction = accessAction.map(x => {
        return {
          id: x.code,
          name: x.name
        }
      })
      if (CUSTOMER !== 'METRO') {
        noAccMenu = ['1000', '2200', '2300', '2301', '2302', '4200', '4201', '9101', '9201', '9102', '11000', '11100', '11200', '5500', '21000']
      } else {
        noAccMenu = ['1000', '2200', '2300', '2301', '2302', '4200', '4201', '9101', '9201', '9102', '21000']
      }
      const disabeldLinks = this.setDisabled(this.disabeldLinks)
      for (const link of disabeldLinks) {
        if (link.disabled === true) noAccMenu.push(...link.codeList)
      }
      const tree = accessAction.reduce((total, currentValue) => {
        const treeItem = {
          id: currentValue.id,
          name: currentValue.name
        }
        // Primary item 이면 마크.
        if (primaryItems.includes(treeItem.id)) treeItem.primary = true
        let parent = null
        for (let index = 0; index < total.length; index++) {
          const element = total[index]

          // LCD
          // if (
          //   !noAccMenu.includes(treeItem.id) && element.id === '20000'
          // ) {
          //   parent = element
          //   break
          // } else
          // if (
          //   !noAccMenu.includes(treeItem.id) &&
          //   (getMenuGroup(treeItem.id) === getMenuGroup(element.id)) && (treeItem.id !== '20000' && treeItem.id !== '21000' &&
          //   treeItem.id !== '22000' && treeItem.id !== '22100' && treeItem.id !== '22001' &&
          //   treeItem.id !== '22002' && treeItem.id !== '22003' && treeItem.id !== '22004' && treeItem.id !== '22005' && treeItem.id !== '22006' && treeItem.id !== '22007' &&
          //   treeItem.id !== '23000' && treeItem.id !== '23100' && treeItem.id !== '23101' && treeItem.id !== '23102' &&
          //   treeItem.id !== '23200' && treeItem.id !== '23201' && treeItem.id !== '23202' && treeItem.id !== '23203' &&
          //   treeItem.id !== '24000' && treeItem.id !== '24100' && treeItem.id !== '24001' && treeItem.id !== '24002' &&
          //    treeItem.id !== '24003' && treeItem.id !== '24004' && treeItem.id !== '25000' && treeItem.id !== '25100' &&
          //   treeItem.id !== '25001' && treeItem.id !== '25002')
          // ) {
          //   parent = element
          //   break
          // }
          // lcd end
          if (
            !noAccMenu.includes(treeItem.id) &&
            getMenuGroup(treeItem.id) === getMenuGroup(element.id)
          ) {
            // Parent가 있는 경우 현재 요소를 해당 Parent의 child로 포함.
            parent = element
            break
          }
        }
        if (parent) {
          // 부모 요소가 존재하는 경우.
          if (!parent.children) parent.children = []
          if (primaryItems.includes(parent.id)) parent.primary = true
          parent.children.push(treeItem)
        } else {
          // 부모 요소 생성되지 않은 경우.
          if (noAccMenu.indexOf(treeItem.id) === -1) {
            total.push(treeItem)
          }
        }
        return total
      }, [])
      tree.forEach(x => {
        if (!x.children) {
          x.children = [
            {
              id: x.id,
              name: x.name
            }
          ]
          x.id = x.id + 1
        }
      })
      return tree
    },
    getAccessLevels () {
      this.$utils
        .callAxios(
          requests.getAccessLevels.method,
          requests.getAccessLevels.url,
          {
            params: {
              company: this.company
            }
          }
        )
        .then(({ data }) => {
          this.accessLevelList = data.accessLevelList.map(accessLevel => {
            accessLevel.editable = false
            return accessLevel
          })
          if (!commons.notEmpty(this.selectedAcc)) {
            this.initSelected()
          }
        })
    },
    initSelected () {
      const userLevel = this.$store.getters['auth/GET_USER_LEVEL']
      const userLevelList = this.accessLevelList.filter(list => {
        return list.accessLevel === userLevel
      })
      this.selectedLevel = userLevelList[0]
      this.selectedAcc.push(userLevelList[0])
      this.selectedMenu = userLevelList[0].accessMenu.slice()
      this.selectedAction = userLevelList[0].accessAction.slice()
    },
    onTitleEdit (level) {
      level.editable = !level.editable
      if (!level.editable) {
        this.$utils.callAxiosWithBody(
          requests.updateAcessLevelTitle.method,
          requests.updateAcessLevelTitle.url,
          {
            title: level.title
          },
          {
            params: {
              company: this.company,
              accessLevel: level.accessLevel
            }
          }
        )
      }
    },
    isAccessLevel (accessLevel) {
      const userLevel = this.$store.getters['auth/GET_USER_LEVEL']
      return Number(accessLevel) >= Number(userLevel)
    },
    onRowClickAccessLevel (level, row) {
      if (level.accessLevel === '1') {
        if (!this.isAccessLevel(level.accessLevel)) {
          EventBus.$emit('messageAlert', this.$t('You have no permission'))
          const lslevel = JSON.parse(window.localStorage.getItem('level'))
          this.selectedMenu = lslevel.accessMenu
          this.selectedAction = lslevel.accessAction
          window.localStorage.removeItem('level')
        } else {
          row.select(true)
          this.selectedLevel = level
          this.selectedMenu = this.selectedLevel.accessMenu
          this.selectedAction = this.selectedLevel.accessAction
        }
      } else {
        window.localStorage.setItem('level', JSON.stringify(level))
        if (level.accessLevel === this.$store.getters['auth/GET_USER_LEVEL']) {
          this.buttondisabled = true
        } else {
          this.buttondisabled = false
        }
        if (level.accessLevel === '1' && this.$store.getters['auth/GET_USER_LEVEL'] === '1') {
          this.buttondisabled = false
        }

        row.select(true)
        this.selectedLevel = level
        this.selectedMenu = this.selectedLevel.accessMenu
        this.selectedAction = this.selectedLevel.accessAction
      }
    },
    onSaveMenu () {
      if (!commons.isValidStr(this.selectedMenu)) return
      const accessMenu = this.selectedMenu.concat(essentialItems)
      this.$utils
        .callAxiosWithBody(
          requests.updateAccessMenu.method,
          requests.updateAccessMenu.url,
          accessMenu,
          {
            params: {
              company: this.company,
              accessLevel: this.selectedLevel.accessLevel
            }
          }
        )
        .then(({ data }) => {
          EventBus.$emit('messageAlert', this.$t('Saved successfully'))
          if (this.editingMyLevel) { this.$store.dispatch('auth/updateAccessMenu', accessMenu) }
          this.getAccessLevels()
        })
        .catch(error => {
          console.debug(`failed to save menu. erorr: ${error}`)
        })
    },
    onResetMenu () {
      if (!this.selectedLevel) {
        return
      }
      this.$utils
        .callAxios(
          requests.getDefaultAccessMenu.method,
          requests.getDefaultAccessMenu.url,
          {
            params: {
              company: this.company,
              accessLevel: this.selectedLevel.accessLevel
            }
          }
        )
        .then(res => {
          EventBus.$emit(
            'messageAlert',
            this.$t('Reset Menu Access Successfully')
          )
          if (this.editingMyLevel) { this.$store.dispatch('auth/updateAccessMenu', res.data.accessMenu) }
          this.selectedMenu = res.data.accessMenu
        })
        .catch(error => {
          console.debug(`failed to reset menu. erorr: ${error}`)
        })
    },
    onSaveAction () {
      if (!this.selectedLevel) return
      const accessMenu = this.selectedAction.concat(essentialAccessItems)
      this.$utils
        .callAxiosWithBody(
          requests.updateAccessAction.method,
          requests.updateAccessAction.url,
          accessMenu,
          {
            params: {
              company: this.company,
              accessLevel: this.selectedLevel.accessLevel
            }
          }
        )
        .then(({ data }) => {
          EventBus.$emit('messageAlert', this.$t('Saved successfully'))
          if (this.editingMyLevel) { this.$store.dispatch('auth/updateAccessAction', accessMenu) }
          this.getAccessLevels()
        })
        .catch(error => {
          console.debug(`failed to reset permissions. erorr: ${error}`)
        })
    },
    onResetAction () {
      if (!this.selectedLevel) {
        return
      }
      this.$utils
        .callAxios(
          requests.getDefaultAccessAction.method,
          requests.getDefaultAccessAction.url,
          {
            params: {
              company: this.company,
              accessLevel: this.selectedLevel.accessLevel
            }
          }
        )
        .then(({ data }) => {
          EventBus.$emit('messageAlert', this.$t('reset successful'))
          if (this.editingMyLevel) {
            this.$store.dispatch(
              'auth/updateAccessAction',
              data.accessAction.concat(primaryAccess)
            )
          }
          this.selectedAction = data.accessAction.concat(primaryAccess)
        })
        .catch(error => {
          console.debug(`failed to reset permissions. erorr: ${error}`)
        })
    },
    setDisabled (disabeldLinks) {
      for (const link of disabeldLinks) {
        link.disabled = this.user[link.key] === 'false'
      }
      return disabeldLinks
    }
  }
}
</script>
<style>
.tbl-type03 tbody td{height:70px !important; }
.treeBox{height:570px}
</style>
