var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-user" }),
        _vm._v(_vm._s(_vm.$t("User Roles")) + " ")
      ]),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-data-table", {
                staticClass: "tbl-type03",
                attrs: {
                  headers: _vm.tableHeaders,
                  items: _vm.accessLevelList,
                  "item-key": "accessLevel",
                  page: _vm.page,
                  "hide-default-footer": true,
                  "single-select": "",
                  height: "650"
                },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "click:row": _vm.onRowClickAccessLevel,
                  "page-count": function($event) {
                    _vm.pageCount = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.title",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.editable
                            ? _c("v-text-field", {
                                staticClass: "form-input",
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  placeholder: "item.title",
                                  readolny: "",
                                  clearable: ""
                                },
                                model: {
                                  value: item.title,
                                  callback: function($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.$t(item.title)))])
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.accessLevel != 1
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onTitleEdit(item)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/ico-edit.png"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedAcc,
                  callback: function($$v) {
                    _vm.selectedAcc = $$v
                  },
                  expression: "selectedAcc"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-center",
              attrs: { cols: "1" }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/ico-next-step.png"),
                  alt: ""
                }
              })
            ]
          ),
          _c("v-col", { attrs: { cols: "4" } }, [
            _c("h3", { staticClass: "tit-treeBox" }, [
              _vm._v(_vm._s(_vm.$t("MENU ACCESS")))
            ]),
            _c(
              "div",
              { staticClass: "treeBox" },
              [
                [
                  _c("v-treeview", {
                    staticClass: "tree_selector",
                    attrs: {
                      items: _vm.accessMenuTree,
                      "item-disabled": "primary",
                      "open-all": "",
                      selectable: "",
                      dense: "",
                      shaped: "",
                      "selected-color": "#001e38"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [_vm._v(_vm._s(_vm.$t(item.name)))])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedMenu,
                      callback: function($$v) {
                        _vm.selectedMenu = $$v
                      },
                      expression: "selectedMenu"
                    }
                  })
                ]
              ],
              2
            ),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn t-bottom",
                    attrs: {
                      disabled:
                        _vm.userLevelSaveResetDisabled || _vm.buttondisabled,
                      text: ""
                    },
                    on: { click: _vm.onSaveMenu }
                  },
                  [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn t-bottom ml-2",
                    attrs: {
                      disabled:
                        _vm.userLevelSaveResetDisabled || _vm.buttondisabled,
                      text: ""
                    },
                    on: { click: _vm.onResetMenu }
                  },
                  [_vm._v(_vm._s(_vm.$t("Reset")) + " ")]
                )
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "4" } }, [
            _c("h3", { staticClass: "tit-treeBox" }, [
              _vm._v(_vm._s(_vm.$t("ACTION ACCESS")))
            ]),
            _c(
              "div",
              { staticClass: "treeBox" },
              [
                [
                  _c("v-treeview", {
                    staticClass: "tree_selector",
                    attrs: {
                      items: _vm.accessActionTree,
                      "item-disabled": "primary",
                      "open-all": "",
                      selectable: "",
                      dense: "",
                      shaped: "",
                      "selected-color": "#001e38"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [_vm._v(_vm._s(_vm.$t(item.name)))])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedAction,
                      callback: function($$v) {
                        _vm.selectedAction = $$v
                      },
                      expression: "selectedAction"
                    }
                  })
                ]
              ],
              2
            ),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn t-bottom",
                    attrs: {
                      disabled:
                        _vm.userLevelSaveResetDisabled || _vm.buttondisabled,
                      text: ""
                    },
                    on: { click: _vm.onSaveAction }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn t-bottom ml-2",
                    attrs: {
                      disabled:
                        _vm.userLevelSaveResetDisabled || _vm.buttondisabled,
                      text: ""
                    },
                    on: { click: _vm.onResetAction }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Reset")) + " ")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }